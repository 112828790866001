$(document).ready(function () {
    initNav($('.js-nav-button'), $('.js-nav-panel'));

    if ($('.js-slideshow').length) {
        initExpertsSlider($('.js-slideshow'));
    }

    if ($('.js-departments').length) {
        initDepartmentsSlider($('.js-departments'));
    }

    if ($('.js-phil').length) {
        initPhilSlider($('.js-phil'));
    }

    if ($('.flow').length) {
        initFlow($('.flow'));
    }

    if ($('.datepickerfield').length) {
        initDatepicker($('.datepickerfield'));
    }

    initCookiebanner($('.cookiebanner'));
});

function initCookiebanner(cookiebanner) {
    if (!getCookie('sertas')) {
        cookiebanner.removeClass('is-hidden');
    }

    var cookie = (getCookie('sertas') ? getCookie('sertas') : 99);
    var edit = cookiebanner.find('.cookiebanner-edit');
    var submitAll = cookiebanner.find('.cookiebanner-submit-all');
    var submit = cookiebanner.find('.cookiebanner-submit');
    var value = cookiebanner.find('.cookiebanner-value');

    $('[href="#cookie"]').on('click', function (event) {
        event.preventDefault();
        cookie = (getCookie('sertas') ? getCookie('sertas') : 99);
        if (cookie == 1) {
            value.prop('checked', true);
        } else {
            value.prop('checked', false);
        }
        cookiebanner.find('#tab-1').addClass('is-hidden');
        cookiebanner.find('#tab-2').removeClass('is-hidden');
        cookiebanner.removeClass('is-hidden');
    });

    edit.on('click', function (event) {
        event.preventDefault();
        cookiebanner.find('#tab-1').addClass('is-hidden');
        cookiebanner.find('#tab-2').removeClass('is-hidden');
    });

    submitAll.on('click', function (event) {
        event.preventDefault();
        cookie = 1;
        setCookie('sertas', cookie, 90);
        window.dataLayer.push({
            'event': 'updateCookie',
            'cookie': cookie
        });
        cookiebanner.addClass('is-hidden');
    });

    submit.on('click', function (event) {
        event.preventDefault();
        if (value.is(':checked')) {
            cookie = 1;
        } else {
            cookie = 99;
        }
        setCookie('sertas', cookie, 90);
        window.dataLayer.push({
            'event': 'updateCookie',
            'cookie': cookie
        });
        cookiebanner.addClass('is-hidden');
    });
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; secure; samesite=strict; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function initDatepicker(datepicker) {
    datepicker.each(function () {
        new Pikaday({
            field: this,
            format: 'DD/MM/YYYY',
            i18n: {
                previousMonth: 'Vorige maand',
                nextMonth: 'Volgende maand',
                months: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
                weekdays: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
                weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
            }
        })
    });
}

function initFlow(flow) {
    flow.find('.flow__next').on('click', function (event) {
        event.preventDefault();

        if (!$(this).hasClass('is-disabled')) {
            var nextItem = $(this).closest('.flow__list-item').next();
            nextItem.removeClass('is-hidden');

            var scrollDistance = nextItem.offset().top - parseInt(nextItem.css('padding-top')) - parseInt(nextItem.css('margin-top'));

            $("html, body").animate({
                scrollTop: scrollDistance
            }, 1000);
        }
    });

    flow.find('.flow__list-item').on('click', '.flow__option', function (event) {
        if ($(this).closest('.flow__answer').hasClass('is-disabled')) {
            event.preventDefault();
        }

        if (!$(this).closest('.flow__answer').siblings('.flow__answer.is-disabled').length) {
            $(this).closest('.flow__answer').siblings('.flow__answer').addClass('is-disabled');
            $(this).closest('.flow__list-item').find('.flow__next').removeClass('is-disabled');
        }
    });
}

function initNav(toggle, wrapper) {
    toggle.on('click', function (event) {
        event.preventDefault();
        toggle.toggleClass('is-active');
        wrapper.toggleClass('is-active');
        $('body').toggleClass('is-fixed');
    })
}

function initExpertsSlider(slider) {
    const expertSlider = slider.find('.js-slideshow-slides').slick({
        infinite: true,
        dots: false,
        arrows: false,
        mobileFirst: true,
        responsive: [{
            breakpoint: 480,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1
            }
        }]
    });

    slider.find('.js-slideshow-prev').on('click', function (event) {
        event.preventDefault();
        expertSlider.slick('slickPrev');
    });

    slider.find('.js-slideshow-next').on('click', function (event) {
        event.preventDefault();
        expertSlider.slick('slickNext');
    });
}

function initDepartmentsSlider(slider) {
    const departmentsSlider = slider.find('.js-slideshow-slides').slick({
        infinite: true,
        dots: false,
        arrows: false,
        mobileFirst: true,
        responsive: [{
            breakpoint: 480,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1
            }
        }]
    });

    slider.find('.js-slideshow-prev').on('click', function (event) {
        event.preventDefault();
        departmentsSlider.slick('slickPrev');
    });

    slider.find('.js-slideshow-next').on('click', function (event) {
        event.preventDefault();
        departmentsSlider.slick('slickNext');
    });
}

function initPhilSlider(slider) {
    const philSlider = slider.find('.js-phil-slides').slick({
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        //appendDots: $('.js-phil-dots'),
        responsive: [{
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });

    const countSlides = slider.find('.phil__slideshow__slide').length;

    let currentSlidesToShow = philSlider.slick('slickGetOption', 'slidesToShow');

    if (philSlider.slick('slickCurrentSlide') > 0) {
        slider.find('.phil__controls__control--next').removeClass('is-hidden');
    } else {
        slider.find('.phil__controls__control--next').addClass('is-hidden');
    }

    if (philSlider.slick('slickCurrentSlide') + currentSlidesToShow >= countSlides) {
        slider.find('.phil__controls__control--prev').addClass('is-hidden');
    } else {
        slider.find('.phil__controls__control--prev').removeClass('is-hidden');
    }

    philSlider.on('afterChange', function (slick) {
        let i = philSlider.slick('slickCurrentSlide') + 1;
        
        $('.js-part-' + i).removeClass('is-hidden');

        let currentSlidesToShow = philSlider.slick('slickGetOption', 'slidesToShow');

        if (philSlider.slick('slickCurrentSlide') > 0) {
            slider.find('.phil__controls__control--next').removeClass('is-hidden');
        } else {
            slider.find('.phil__controls__control--next').addClass('is-hidden');
        }

        if (philSlider.slick('slickCurrentSlide') + currentSlidesToShow >= countSlides) {
            slider.find('.phil__controls__control--prev').addClass('is-hidden');
        } else {
            slider.find('.phil__controls__control--prev').removeClass('is-hidden');
        }
    });

    slider.find('.js-phil-prev').on('click', function (event) {
        event.preventDefault();
        philSlider.slick('slickPrev');
    });

    slider.find('.js-phil-next').on('click', function (event) {
        event.preventDefault();
        philSlider.slick('slickNext');
    });
}